:root {
  --calendar-border: #e0e0e0 1px solid;
  --calendar-border-dark: #71755d 1px solid;
  --calendar-border-section: #e0e0e0 1px dashed;
  --calendar-border-section-dark: #71755d 1px dashed;
  --calendar-border-current: #027BE3 2px solid;
  --calendar-border-current-dark: #ffff66 2px solid;
  --calendar-border-droppable: #027BE3 1px dashed;
  --calendar-border-droppable-dark: #ffff66 1px dashed;
  --calendar-mini-range-connector-hover-border: #027BE3 1px dashed;
  --calendar-mini-range-connector-hover-border-dark: #ffff66 1px dashed;
  --calendar-color: #606c71;
  --calendar-color-dark: #fafafa;
  --calendar-background: #ffffff;
  --calendar-background-dark: #121212;
  --calendar-current-color: #027BE3;
  --calendar-current-color-dark: #ffff66;
  --calendar-current-background: #00000000;
  --calendar-current-background-dark: #121212;
  --calendar-disabled-date-color: #a1a1a1;
  --calendar-disabled-date-color-dark: #bebebe;
  --calendar-disabled-date-background: #ffffff;
  --calendar-disabled-date-background-dark: #121212;
  --calendar-active-date-color: #027BE3;
  --calendar-active-date-color-dark: #ffff66;
  --calendar-active-date-background: #ddccf7;
  --calendar-active-date-background-dark: #8f44ff;
  --calendar-outside-color: #606c71;
  --calendar-outside-color-dark: #bebebe;
  --calendar-outside-background: #00000000;
  --calendar-outside-background-dark: #121212;
  --calendar-selected-color: #027BE3;
  --calendar-selected-color-dark: #027BE3;
  --calendar-selected-background: #cce7ff;
  --calendar-selected-background-dark: #cce7ff;
  --calendar-mini-selected-color: #027BE3;
  --calendar-mini-selected-color-dark: #027BE3;
  --calendar-mini-selected-background: #00000000;
  --calendar-mini-selected-background-dark: #00000000;
  --calendar-mini-selected-label-color: #027BE3;
  --calendar-mini-selected-label-color-dark: #cce7ff;
  --calendar-mini-selected-label-background: #cce7ff;
  --calendar-mini-selected-label-background-dark: #027BE3;
  --calendar-range-color: #027BE3;
  --calendar-range-color-dark: #027BE3;
  --calendar-range-background: #cce7ff;
  --calendar-range-background-dark: #cce7ff;
  --calendar-mini-range-color: #cce7ff;
  --calendar-mini-range-color-dark: #027BE3;
  --calendar-mini-range-background: #00000000;
  --calendar-mini-range-background-dark: #00000000;
  --calendar-mini-range-label-color: #cce7ff;
  --calendar-mini-range-label-color-dark: #027BE3;
  --calendar-mini-range-label-background: #cce7ff;
  --calendar-mini-range-label-background-dark: #cce7ff;
  --calendar-mini-range-connector-color: #cce7ff;
  --calendar-mini-range-connector-color-dark: #ffff66;
  --calendar-mini-range-hover-color: #027BE3;
  --calendar-mini-range-hover-color-dark: #ffff66;
  --calendar-mini-range-firstlast-color: #cce7ff;
  --calendar-mini-range-firstlast-color-dark: #cce7ff;
  --calendar-mini-range-firstlast-background: #00000000;
  --calendar-mini-range-firstlast-background-dark: #cce7ff;
  --calendar-mini-range-firstlast-label-color: #cce7ff;
  --calendar-mini-range-firstlast-label-color-dark: #cce7ff;
  --calendar-mini-range-firstlast-label-background: #027BE3;
  --calendar-mini-range-firstlast-label-background-dark: #ffff66;
  --calendar-intervals-width: 56px;
  --calendar-work-week-width: 30px;
  --calendar-mini-work-week-width: 30px;
  --calendar-work-week-font-size: 1.0em;
  --calendar-head-font-weight: 600;
}

.q-calendar {
  color: var(--calendar-color);
  background: var(--calendar-background);
  width: 100%;
  min-width: auto;
  overflow: hidden;
}
.q-calendar__bordered {
  border: var(--calendar-border);
}

.q-dark div.q-calendar,
.body--dark div.q-calendar,
.q-calendar--dark.q-calendar {
  color: var(--calendar-color-dark);
  background: var(--calendar-background-dark);
}
.q-dark div.q-calendar__bordered,
.body--dark div.q-calendar__bordered,
.q-calendar--dark.q-calendar__bordered {
  border: var(--calendar-border-dark);
}

.q-calendar-daily,
.q-calendar-agenda {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.q-calendar-daily__head,
.q-calendar-agenda__head {
  flex: none;
  display: flex;
  overflow: hidden;
  min-height: 25px;
}
.q-calendar-daily__intervals-head,
.q-calendar-agenda__intervals-head {
  flex: none;
  display: flex;
  overflow: hidden;
  vertical-align: bottom;
}
.q-calendar-daily__intervals-head--text,
.q-calendar-agenda__intervals-head--text {
  display: block;
  position: relative;
  font-size: 10px;
  text-align: center;
  vertical-align: bottom;
}
.q-calendar-daily__head-day,
.q-calendar-agenda__head-day {
  flex: 1 1 auto;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 0;
}
.q-calendar-daily__head-weekday,
.q-calendar-agenda__head-weekday {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  user-select: none;
  padding: 4px;
  font-size: 1rem;
  justify-content: left;
}
.q-calendar-daily__head-day-label,
.q-calendar-agenda__head-day-label {
  user-select: none;
  cursor: pointer;
  left: 2px;
  bottom: 2px;
}
.q-calendar-daily__column-header__before,
.q-calendar-agenda__column-header__before {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  user-select: none;
  padding: 4px;
  font-size: 1rem;
  justify-content: left;
}
.q-calendar-daily__column-header__after,
.q-calendar-agenda__column-header__after {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  user-select: none;
  padding: 4px;
  font-size: 1rem;
  justify-content: left;
}
.q-calendar-daily__body,
.q-calendar-agenda__body {
  flex: 1 1 60%;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
}
.q-calendar-daily__scroll-area,
.q-calendar-agenda__scroll-area {
  overflow-y: scroll;
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
}
.q-calendar-daily__pane,
.q-calendar-agenda__pane {
  width: 100%;
  overflow-y: hidden;
  flex: none;
  display: flex;
  align-items: flex-start;
}
.q-calendar-daily__day-container,
.q-calendar-agenda__day-container {
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}
.q-calendar-daily__intervals-body,
.q-calendar-agenda__intervals-body {
  flex: none;
  user-select: none;
}
.q-calendar-daily__interval,
.q-calendar-agenda__interval {
  text-align: center;
  border-bottom: none;
}
.q-calendar-daily__interval-text,
.q-calendar-agenda__interval-text {
  display: block;
  position: relative;
  top: -6px;
  font-size: 10px;
}
.q-calendar-daily__day,
.q-calendar-agenda__day {
  flex: 1;
  width: 0;
  position: relative;
}

.q-calendar-daily__intervals-head,
.q-calendar-agenda__intervals-head {
  border-right: var(--calendar-border);
  min-width: var(--calendar-intervals-width);
  max-width: var(--calendar-intervals-width);
}
.q-calendar-daily__head-day,
.q-calendar-agenda__head-day {
  font-weight: var(--calendar-head-font-weight);
  border-right: var(--calendar-border);
}
.q-calendar-daily__head-day:last-child,
.q-calendar-agenda__head-day:last-child {
  border-right: none;
}
.q-calendar-daily__head-day.q-active-date .q-btn,
.q-calendar-agenda__head-day.q-active-date .q-btn {
  color: var(--calendar-active-date-color);
}
.q-calendar-daily__head-day.q-active-date .q-btn__wrapper:before,
.q-calendar-agenda__head-day.q-active-date .q-btn__wrapper:before {
  background: var(--calendar-active-date-background);
}
.q-calendar-daily__head-day.q-current-day,
.q-calendar-agenda__head-day.q-current-day {
  color: var(--calendar-current-date-color);
}
.q-calendar-daily__head-day.q-current-day .q-btn__wrapper:before,
.q-calendar-agenda__head-day.q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current);
}
.q-calendar-daily__head-day.q-disabled-day,
.q-calendar-agenda__head-day.q-disabled-day {
  color: var(--calendar-disabled-date-color);
  background: var(--calendar-disabled-date-background) !important;
}
.q-calendar-daily__column-header--before,
.q-calendar-agenda__column-header--before {
  border-bottom: var(--calendar-border);
}
.q-calendar-daily__column-header--after,
.q-calendar-agenda__column-header--after {
  border-top: var(--calendar-border);
}
.q-calendar-daily__body,
.q-calendar-agenda__body {
  border-top: var(--calendar-border);
}
.q-calendar-daily__intervals-body,
.q-calendar-agenda__intervals-body {
  border-right: var(--calendar-border);
  min-width: var(--calendar-intervals-width);
  max-width: var(--calendar-intervals-width);
}
.q-calendar-daily__day,
.q-calendar-agenda__day {
  border-right: var(--calendar-border);
}
.q-calendar-daily__day:last-child,
.q-calendar-agenda__day:last-child {
  border-right: none !important;
}
.q-calendar-daily__day.q-disabled-day,
.q-calendar-agenda__day.q-disabled-day {
  color: var(--calendar-disabled-date-color);
  background: var(--calendar-disabled-date-background) !important;
}
.q-calendar-daily__day-interval,
.q-calendar-agenda__day-interval {
  width: 100%;
  border-top: var(--calendar-border);
}
.q-calendar-daily__day-interval.q-selected,
.q-calendar-agenda__day-interval.q-selected {
  color: var(--calendar-selected-color);
  background: var(--calendar-selected-background);
}
.q-calendar-daily__day-interval.q-range-first, .q-calendar-daily__day-interval.q-range-last, .q-calendar-daily__day-interval.q-range,
.q-calendar-agenda__day-interval.q-range-first,
.q-calendar-agenda__day-interval.q-range-last,
.q-calendar-agenda__day-interval.q-range {
  color: var(--calendar-range-color);
  background: var(--calendar-range-background);
}
.q-calendar-daily__day-interval--section,
.q-calendar-agenda__day-interval--section {
  border-top: var(--calendar-border-section);
}
.q-calendar-daily__day-interval--section.q-selected,
.q-calendar-agenda__day-interval--section.q-selected {
  color: var(--calendar-selected-color);
  background: var(--calendar-selected-background);
}
.q-calendar-daily__day-interval--section.q-range-first, .q-calendar-daily__day-interval--section.q-range-last, .q-calendar-daily__day-interval--section.q-range,
.q-calendar-agenda__day-interval--section.q-range-first,
.q-calendar-agenda__day-interval--section.q-range-last,
.q-calendar-agenda__day-interval--section.q-range {
  color: var(--calendar-range-color);
  background: var(--calendar-range-background);
}
.q-calendar-daily__day-interval:first-child,
.q-calendar-agenda__day-interval:first-child {
  border-top: none !important;
}
.q-calendar-daily__day-interval:last-child,
.q-calendar-agenda__day-interval:last-child {
  border-bottom: none !important;
}
.q-calendar-daily__day-interval--droppable,
.q-calendar-agenda__day-interval--droppable {
  border: var(--calendar-border-droppable);
}
.q-calendar-daily__day--droppable,
.q-calendar-agenda__day--droppable {
  border: var(--calendar-border-droppable);
}

.q-dark div .q-calendar-daily__intervals-head,
.q-dark div .q-calendar-agenda__intervals-head,
.body--dark div .q-calendar-daily__intervals-head,
.body--dark div .q-calendar-agenda__intervals-head,
.q-calendar--dark .q-calendar-daily__intervals-head,
.q-calendar--dark .q-calendar-agenda__intervals-head {
  border-right: var(--calendar-border-dark);
}
.q-dark div .q-calendar-daily__head-day,
.q-dark div .q-calendar-agenda__head-day,
.body--dark div .q-calendar-daily__head-day,
.body--dark div .q-calendar-agenda__head-day,
.q-calendar--dark .q-calendar-daily__head-day,
.q-calendar--dark .q-calendar-agenda__head-day {
  border-right: var(--calendar-border-dark);
  font-weight: var(--calendar-head-font-weight);
}
.q-dark div .q-calendar-daily__head-day:last-child,
.q-dark div .q-calendar-agenda__head-day:last-child,
.body--dark div .q-calendar-daily__head-day:last-child,
.body--dark div .q-calendar-agenda__head-day:last-child,
.q-calendar--dark .q-calendar-daily__head-day:last-child,
.q-calendar--dark .q-calendar-agenda__head-day:last-child {
  border-right: none;
}
.q-dark div .q-calendar-daily__head-day.q-active-date .q-btn,
.q-dark div .q-calendar-agenda__head-day.q-active-date .q-btn,
.body--dark div .q-calendar-daily__head-day.q-active-date .q-btn,
.body--dark div .q-calendar-agenda__head-day.q-active-date .q-btn,
.q-calendar--dark .q-calendar-daily__head-day.q-active-date .q-btn,
.q-calendar--dark .q-calendar-agenda__head-day.q-active-date .q-btn {
  color: var(--calendar-active-date-color-dark);
}
.q-dark div .q-calendar-daily__head-day.q-active-date .q-btn__wrapper:before,
.q-dark div .q-calendar-agenda__head-day.q-active-date .q-btn__wrapper:before,
.body--dark div .q-calendar-daily__head-day.q-active-date .q-btn__wrapper:before,
.body--dark div .q-calendar-agenda__head-day.q-active-date .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-daily__head-day.q-active-date .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-agenda__head-day.q-active-date .q-btn__wrapper:before {
  background: var(--calendar-active-date-background-dark);
}
.q-dark div .q-calendar-daily__head-day.q-current-day,
.q-dark div .q-calendar-agenda__head-day.q-current-day,
.body--dark div .q-calendar-daily__head-day.q-current-day,
.body--dark div .q-calendar-agenda__head-day.q-current-day,
.q-calendar--dark .q-calendar-daily__head-day.q-current-day,
.q-calendar--dark .q-calendar-agenda__head-day.q-current-day {
  color: var(--calendar-current-date-color-dark);
}
.q-dark div .q-calendar-daily__head-day.q-current-day .q-btn__wrapper:before,
.q-dark div .q-calendar-agenda__head-day.q-current-day .q-btn__wrapper:before,
.body--dark div .q-calendar-daily__head-day.q-current-day .q-btn__wrapper:before,
.body--dark div .q-calendar-agenda__head-day.q-current-day .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-daily__head-day.q-current-day .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-agenda__head-day.q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current-dark);
}
.q-dark div .q-calendar-daily.q-disabled-day,
.q-dark div .q-calendar-agenda.q-disabled-day,
.body--dark div .q-calendar-daily.q-disabled-day,
.body--dark div .q-calendar-agenda.q-disabled-day,
.q-calendar--dark .q-calendar-daily.q-disabled-day,
.q-calendar--dark .q-calendar-agenda.q-disabled-day {
  color: var(--calendar-disabled-date-color-dark);
  background: var(--calendar-disabled-date-background-dark) !important;
}
.q-dark div .q-calendar-daily__column-header--before,
.q-dark div .q-calendar-agenda__column-header--before,
.body--dark div .q-calendar-daily__column-header--before,
.body--dark div .q-calendar-agenda__column-header--before,
.q-calendar--dark .q-calendar-daily__column-header--before,
.q-calendar--dark .q-calendar-agenda__column-header--before {
  border-bottom: var(--calendar-border-dark);
}
.q-dark div .q-calendar-daily__column-header--after,
.q-dark div .q-calendar-agenda__column-header--after,
.body--dark div .q-calendar-daily__column-header--after,
.body--dark div .q-calendar-agenda__column-header--after,
.q-calendar--dark .q-calendar-daily__column-header--after,
.q-calendar--dark .q-calendar-agenda__column-header--after {
  border-top: var(--calendar-border-dark);
}
.q-dark div .q-calendar-daily__body,
.q-dark div .q-calendar-agenda__body,
.body--dark div .q-calendar-daily__body,
.body--dark div .q-calendar-agenda__body,
.q-calendar--dark .q-calendar-daily__body,
.q-calendar--dark .q-calendar-agenda__body {
  border-top: var(--calendar-border-dark);
}
.q-dark div .q-calendar-daily__intervals-body,
.q-dark div .q-calendar-agenda__intervals-body,
.body--dark div .q-calendar-daily__intervals-body,
.body--dark div .q-calendar-agenda__intervals-body,
.q-calendar--dark .q-calendar-daily__intervals-body,
.q-calendar--dark .q-calendar-agenda__intervals-body {
  border-right: var(--calendar-border-dark);
}
.q-dark div .q-calendar-daily__day,
.q-dark div .q-calendar-agenda__day,
.body--dark div .q-calendar-daily__day,
.body--dark div .q-calendar-agenda__day,
.q-calendar--dark .q-calendar-daily__day,
.q-calendar--dark .q-calendar-agenda__day {
  border-right: var(--calendar-border-dark);
}
.q-dark div .q-calendar-daily__day:last-child,
.q-dark div .q-calendar-agenda__day:last-child,
.body--dark div .q-calendar-daily__day:last-child,
.body--dark div .q-calendar-agenda__day:last-child,
.q-calendar--dark .q-calendar-daily__day:last-child,
.q-calendar--dark .q-calendar-agenda__day:last-child {
  border-right: none !important;
}
.q-dark div .q-calendar-daily__day.q-disabled-day,
.q-dark div .q-calendar-agenda__day.q-disabled-day,
.body--dark div .q-calendar-daily__day.q-disabled-day,
.body--dark div .q-calendar-agenda__day.q-disabled-day,
.q-calendar--dark .q-calendar-daily__day.q-disabled-day,
.q-calendar--dark .q-calendar-agenda__day.q-disabled-day {
  color: var(--calendar-disabled-date-color);
  background: var(--calendar-disabled-date-background) !important;
}
.q-dark div .q-calendar-daily__day-interval,
.q-dark div .q-calendar-agenda__day-interval,
.body--dark div .q-calendar-daily__day-interval,
.body--dark div .q-calendar-agenda__day-interval,
.q-calendar--dark .q-calendar-daily__day-interval,
.q-calendar--dark .q-calendar-agenda__day-interval {
  border-top: var(--calendar-border-dark);
}
.q-dark div .q-calendar-daily__day-interval.q-selected,
.q-dark div .q-calendar-agenda__day-interval.q-selected,
.body--dark div .q-calendar-daily__day-interval.q-selected,
.body--dark div .q-calendar-agenda__day-interval.q-selected,
.q-calendar--dark .q-calendar-daily__day-interval.q-selected,
.q-calendar--dark .q-calendar-agenda__day-interval.q-selected {
  color: var(--calendar-selected-color-dark);
  background: var(--calendar-selected-background-dark);
}
.q-dark div .q-calendar-daily__day-interval.q-range-first, .q-dark div .q-calendar-daily__day-interval.q-range-last, .q-dark div .q-calendar-daily__day-interval.q-range,
.q-dark div .q-calendar-agenda__day-interval.q-range-first,
.q-dark div .q-calendar-agenda__day-interval.q-range-last,
.q-dark div .q-calendar-agenda__day-interval.q-range,
.body--dark div .q-calendar-daily__day-interval.q-range-first,
.body--dark div .q-calendar-daily__day-interval.q-range-last,
.body--dark div .q-calendar-daily__day-interval.q-range,
.body--dark div .q-calendar-agenda__day-interval.q-range-first,
.body--dark div .q-calendar-agenda__day-interval.q-range-last,
.body--dark div .q-calendar-agenda__day-interval.q-range,
.q-calendar--dark .q-calendar-daily__day-interval.q-range-first,
.q-calendar--dark .q-calendar-daily__day-interval.q-range-last,
.q-calendar--dark .q-calendar-daily__day-interval.q-range,
.q-calendar--dark .q-calendar-agenda__day-interval.q-range-first,
.q-calendar--dark .q-calendar-agenda__day-interval.q-range-last,
.q-calendar--dark .q-calendar-agenda__day-interval.q-range {
  color: var(--calendar-range-color-dark);
  background: var(--calendar-range-background-dark);
}
.q-dark div .q-calendar-daily__day-interval:first-child,
.q-dark div .q-calendar-agenda__day-interval:first-child,
.body--dark div .q-calendar-daily__day-interval:first-child,
.body--dark div .q-calendar-agenda__day-interval:first-child,
.q-calendar--dark .q-calendar-daily__day-interval:first-child,
.q-calendar--dark .q-calendar-agenda__day-interval:first-child {
  border-top: none !important;
}
.q-dark div .q-calendar-daily__day-interval:last-child,
.q-dark div .q-calendar-agenda__day-interval:last-child,
.body--dark div .q-calendar-daily__day-interval:last-child,
.body--dark div .q-calendar-agenda__day-interval:last-child,
.q-calendar--dark .q-calendar-daily__day-interval:last-child,
.q-calendar--dark .q-calendar-agenda__day-interval:last-child {
  border-bottom: none !important;
}
.q-dark div .q-calendar-daily__day-interval--section,
.q-dark div .q-calendar-agenda__day-interval--section,
.body--dark div .q-calendar-daily__day-interval--section,
.body--dark div .q-calendar-agenda__day-interval--section,
.q-calendar--dark .q-calendar-daily__day-interval--section,
.q-calendar--dark .q-calendar-agenda__day-interval--section {
  border-top: var(--calendar-border-section-dark);
}
.q-dark div .q-calendar-daily__day-interval--section.q-selected,
.q-dark div .q-calendar-agenda__day-interval--section.q-selected,
.body--dark div .q-calendar-daily__day-interval--section.q-selected,
.body--dark div .q-calendar-agenda__day-interval--section.q-selected,
.q-calendar--dark .q-calendar-daily__day-interval--section.q-selected,
.q-calendar--dark .q-calendar-agenda__day-interval--section.q-selected {
  color: var(--calendar-selected-color-dark);
  background: var(--calendar-selected-background-dark);
}
.q-dark div .q-calendar-daily__day-interval--section.q-range-first, .q-dark div .q-calendar-daily__day-interval--section.q-range-last, .q-dark div .q-calendar-daily__day-interval--section.q-range,
.q-dark div .q-calendar-agenda__day-interval--section.q-range-first,
.q-dark div .q-calendar-agenda__day-interval--section.q-range-last,
.q-dark div .q-calendar-agenda__day-interval--section.q-range,
.body--dark div .q-calendar-daily__day-interval--section.q-range-first,
.body--dark div .q-calendar-daily__day-interval--section.q-range-last,
.body--dark div .q-calendar-daily__day-interval--section.q-range,
.body--dark div .q-calendar-agenda__day-interval--section.q-range-first,
.body--dark div .q-calendar-agenda__day-interval--section.q-range-last,
.body--dark div .q-calendar-agenda__day-interval--section.q-range,
.q-calendar--dark .q-calendar-daily__day-interval--section.q-range-first,
.q-calendar--dark .q-calendar-daily__day-interval--section.q-range-last,
.q-calendar--dark .q-calendar-daily__day-interval--section.q-range,
.q-calendar--dark .q-calendar-agenda__day-interval--section.q-range-first,
.q-calendar--dark .q-calendar-agenda__day-interval--section.q-range-last,
.q-calendar--dark .q-calendar-agenda__day-interval--section.q-range {
  color: var(--calendar-range-color-dark);
  background: var(--calendar-range-background-dark);
}
.q-dark div .q-calendar-daily__day-interval--droppable,
.q-dark div .q-calendar-agenda__day-interval--droppable,
.body--dark div .q-calendar-daily__day-interval--droppable,
.body--dark div .q-calendar-agenda__day-interval--droppable,
.q-calendar--dark .q-calendar-daily__day-interval--droppable,
.q-calendar--dark .q-calendar-agenda__day-interval--droppable {
  border: var(--calendar-border-droppable-dark);
}
.q-dark div .q-calendar-daily__day--droppable,
.q-dark div .q-calendar-agenda__day--droppable,
.body--dark div .q-calendar-daily__day--droppable,
.body--dark div .q-calendar-agenda__day--droppable,
.q-calendar--dark .q-calendar-daily__day--droppable,
.q-calendar--dark .q-calendar-agenda__day--droppable {
  border: var(--calendar-border-droppable-dark);
}

.q-calendar-weekly {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
}
.q-calendar-weekly__head {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex: 1 0 0%;
}
.q-calendar-weekly__head--wrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex: 1 0 0%;
  min-width: 100%;
  max-width: 100%;
}
.q-calendar-weekly__head-workweek {
  display: flex;
  flex-wrap: nowrap;
  user-select: none;
  padding: 0 4px;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.q-calendar-weekly__head-weekdays {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex: 1 0 0%;
  padding: 0;
  font-size: 1rem;
  width: 100%;
}
.q-calendar-weekly__head-weekday {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  user-select: none;
  padding: 0 4px;
  font-size: 1rem;
  justify-content: center;
}
.q-calendar-weekly__week {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex: 10000 1 0%;
  min-height: 5em;
}
.q-calendar-weekly__week--wrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex: 10000 1 0%;
  overflow: hidden;
  min-width: 100%;
  transition: height 0.3s;
}
.q-calendar-weekly__week-days {
  position: relative;
  height: 100%;
  display: flex;
  flex: 1 0 auto;
  flex-wrap: nowrap;
  min-width: 100%;
  width: 100%;
}
.q-calendar-weekly__week-events {
  position: absolute;
  margin-top: 28px;
  width: 100%;
  overflow: hidden;
}
.q-calendar-weekly__workweek {
  display: flex;
  flex-wrap: nowrap;
  user-select: none;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.q-calendar-weekly__day {
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
  user-select: none;
}
.q-calendar-weekly__day--content {
  position: relative;
  margin-top: 28px;
  height: 100%;
}
.q-calendar-weekly__day-label {
  position: absolute;
  text-decoration: none;
  user-select: none;
  left: 2px;
  top: 2px;
}
.q-calendar-weekly__day-month {
  position: absolute;
  text-decoration: none;
  user-select: none;
  max-width: 80%;
  top: 3px;
  right: 2px;
  left: 2px;
  margin-left: 28px;
  text-align: right;
}
.q-calendar-weekly__day-month--day-of-year {
  position: absolute;
  text-decoration: none;
  user-select: none;
  box-shadow: none;
  top: 2px;
  right: 2px;
  font-size: 0.6rem;
}

.q-calendar-weekly__head-workweek {
  border-right: var(--calendar-border);
  border-bottom: var(--calendar-border);
  max-width: var(--calendar-work-week-width);
  min-width: var(--calendar-work-week-width);
}
.q-calendar-weekly__head-weekday {
  border-right: var(--calendar-border);
  border-bottom: var(--calendar-border);
  font-weight: var(--calendar-head-font-weight);
}
.q-calendar-weekly__head-weekday:last-child {
  border-right: none !important;
}
.q-calendar-weekly__head-weekday.q-disabled-day {
  color: var(--calendar-disabled-date-color);
  background: var(--calendar-disabled-date-background) !important;
}
.q-calendar-weekly__workweek {
  font-size: var(--calendar-work-week-font-size);
  border-right: var(--calendar-border);
  max-width: var(--calendar-work-week-width);
  min-width: var(--calendar-work-week-width);
}
.q-calendar-weekly__workweek.q-current-day {
  color: var(--calendar-current-color);
}
.q-calendar-weekly__week--wrapper {
  border-bottom: var(--calendar-border);
}
.q-calendar-weekly__week--wrapper:last-child {
  border-bottom: none !important;
}
.q-calendar-weekly__day {
  border-right: var(--calendar-border);
}
.q-calendar-weekly__day:last-child {
  border-right: none !important;
}
.q-calendar-weekly__day.q-active-date {
  color: var(--calendar-active-date-color);
}
.q-calendar-weekly__day.q-active-date .q-btn__wrapper:before {
  background: var(--calendar-active-date-background);
}
.q-calendar-weekly__day.q-current-day {
  color: var(--calendar-current-color) !important;
  background: var(--calendar-current-background);
}
.q-calendar-weekly__day.q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current);
  background: var(--calendar-current-background);
}
.q-calendar-weekly__day.q-disabled-day {
  color: var(--calendar-disabled-date-color);
  background: var(--calendar-disabled-date-background) !important;
}
.q-calendar-weekly__day.q-outside {
  color: var(--calendar-outside-color) !important;
  background: var(--calendar-outside-background);
}
.q-calendar-weekly__day.q-selected {
  color: var(--calendar-selected-color);
  background: var(--calendar-selected-background);
}
.q-calendar-weekly__day.q-selected .q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current-dark) !important;
}
.q-calendar-weekly__day.q-range-first, .q-calendar-weekly__day.q-range-last, .q-calendar-weekly__day.q-range {
  color: var(--calendar-range-color);
  background: var(--calendar-range-background);
}
.q-calendar-weekly__day.q-range-first .q-current-day .q-btn__wrapper:before, .q-calendar-weekly__day.q-range-last .q-current-day .q-btn__wrapper:before, .q-calendar-weekly__day.q-range .q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current-dark) !important;
}
.q-calendar-weekly__day.q-calendar-weekly__day--droppable {
  border: var(--calendar-border-droppable) !important;
}

.q-dark div .q-calendar-weekly__head-workweek,
.body--dark div .q-calendar-weekly__head-workweek,
.q-calendar--dark .q-calendar-weekly__head-workweek {
  border-right: var(--calendar-border-dark);
  border-bottom: var(--calendar-border-dark);
}
.q-dark div .q-calendar-weekly__head-weekday,
.body--dark div .q-calendar-weekly__head-weekday,
.q-calendar--dark .q-calendar-weekly__head-weekday {
  border-right: var(--calendar-border-dark);
  border-bottom: var(--calendar-border-dark);
}
.q-dark div .q-calendar-weekly__head-weekday:last-child,
.body--dark div .q-calendar-weekly__head-weekday:last-child,
.q-calendar--dark .q-calendar-weekly__head-weekday:last-child {
  border-right: none !important;
}
.q-dark div .q-calendar-weekly__head-weekday.q-disabled-day,
.body--dark div .q-calendar-weekly__head-weekday.q-disabled-day,
.q-calendar--dark .q-calendar-weekly__head-weekday.q-disabled-day {
  color: var(--calendar-disabled-date-color-dark);
  background: var(--calendar-disabled-date-background-dark) !important;
}
.q-dark div .q-calendar-weekly__workweek,
.body--dark div .q-calendar-weekly__workweek,
.q-calendar--dark .q-calendar-weekly__workweek {
  border-right: var(--calendar-border-dark);
}
.q-dark div .q-calendar-weekly__workweek.q-current-day,
.body--dark div .q-calendar-weekly__workweek.q-current-day,
.q-calendar--dark .q-calendar-weekly__workweek.q-current-day {
  color: var(--calendar-current-color-dark);
}
.q-dark div .q-calendar-weekly__week--wrapper,
.body--dark div .q-calendar-weekly__week--wrapper,
.q-calendar--dark .q-calendar-weekly__week--wrapper {
  border-bottom: var(--calendar-border-dark);
}
.q-dark div .q-calendar-weekly__week--wrapper:last-child,
.body--dark div .q-calendar-weekly__week--wrapper:last-child,
.q-calendar--dark .q-calendar-weekly__week--wrapper:last-child {
  border-bottom: none !important;
}
.q-dark div .q-calendar-weekly__day,
.body--dark div .q-calendar-weekly__day,
.q-calendar--dark .q-calendar-weekly__day {
  border-right: var(--calendar-border-dark);
}
.q-dark div .q-calendar-weekly__day:last-child,
.body--dark div .q-calendar-weekly__day:last-child,
.q-calendar--dark .q-calendar-weekly__day:last-child {
  border-right: none !important;
}
.q-dark div .q-calendar-weekly__day.q-active-date,
.body--dark div .q-calendar-weekly__day.q-active-date,
.q-calendar--dark .q-calendar-weekly__day.q-active-date {
  color: var(--calendar-active-date-color-dark) !important;
}
.q-dark div .q-calendar-weekly__day.q-active-date .q-btn__wrapper:before,
.body--dark div .q-calendar-weekly__day.q-active-date .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-weekly__day.q-active-date .q-btn__wrapper:before {
  background: var(--calendar-active-date-background-dark);
}
.q-dark div .q-calendar-weekly__day.q-current-day,
.body--dark div .q-calendar-weekly__day.q-current-day,
.q-calendar--dark .q-calendar-weekly__day.q-current-day {
  color: var(--calendar-current-color-dark) !important;
  background: var(--calendar-current-background-dark);
}
.q-dark div .q-calendar-weekly__day.q-current-day .q-btn__wrapper:before,
.body--dark div .q-calendar-weekly__day.q-current-day .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-weekly__day.q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current-dark) !important;
  background: var(--calendar-current-background-dark);
}
.q-dark div .q-calendar-weekly__day.q-disabled-day,
.body--dark div .q-calendar-weekly__day.q-disabled-day,
.q-calendar--dark .q-calendar-weekly__day.q-disabled-day {
  color: var(--calendar-disabled-date-color-dark);
  background: var(--calendar-disabled-date-background-dark) !important;
}
.q-dark div .q-calendar-weekly__day.q-outside,
.body--dark div .q-calendar-weekly__day.q-outside,
.q-calendar--dark .q-calendar-weekly__day.q-outside {
  color: var(--calendar-outside-color-dark) !important;
  background: var(--calendar-outside-background-dark);
}
.q-dark div .q-calendar-weekly__day.q-selected,
.body--dark div .q-calendar-weekly__day.q-selected,
.q-calendar--dark .q-calendar-weekly__day.q-selected {
  color: var(--calendar-selected-color-dark);
  background: var(--calendar-selected-background-dark);
}
.q-dark div .q-calendar-weekly__day.q-selected .q-current-day .q-btn__wrapper:before,
.body--dark div .q-calendar-weekly__day.q-selected .q-current-day .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-weekly__day.q-selected .q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current) !important;
}
.q-dark div .q-calendar-weekly__day.q-range-first, .q-dark div .q-calendar-weekly__day.q-range-last, .q-dark div .q-calendar-weekly__day.q-range,
.body--dark div .q-calendar-weekly__day.q-range-first,
.body--dark div .q-calendar-weekly__day.q-range-last,
.body--dark div .q-calendar-weekly__day.q-range,
.q-calendar--dark .q-calendar-weekly__day.q-range-first,
.q-calendar--dark .q-calendar-weekly__day.q-range-last,
.q-calendar--dark .q-calendar-weekly__day.q-range {
  color: var(--calendar-range-color-dark);
  background: var(--calendar-range-background-dark);
}
.q-dark div .q-calendar-weekly__day.q-range-first .q-current-day .q-btn__wrapper:before, .q-dark div .q-calendar-weekly__day.q-range-last .q-current-day .q-btn__wrapper:before, .q-dark div .q-calendar-weekly__day.q-range .q-current-day .q-btn__wrapper:before,
.body--dark div .q-calendar-weekly__day.q-range-first .q-current-day .q-btn__wrapper:before,
.body--dark div .q-calendar-weekly__day.q-range-last .q-current-day .q-btn__wrapper:before,
.body--dark div .q-calendar-weekly__day.q-range .q-current-day .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-weekly__day.q-range-first .q-current-day .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-weekly__day.q-range-last .q-current-day .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-weekly__day.q-range .q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current) !important;
}
.q-dark div .q-calendar-weekly__day.q-calendar-weekly__day--droppable,
.body--dark div .q-calendar-weekly__day.q-calendar-weekly__day--droppable,
.q-calendar--dark .q-calendar-weekly__day.q-calendar-weekly__day--droppable {
  border: var(--calendar-border-droppable-dark) !important;
}

.q-day-event:first-child {
  margin-top: 0em;
}

.q-calendar-mini .q-calendar-weekly__head-workweek {
  border-right: unset;
  border-bottom: unset;
  max-width: var(--calendar-mini-work-week-width);
  min-width: var(--calendar-mini-work-week-width);
}
.q-calendar-mini .q-calendar-weekly__head-weekday {
  border: 0 !important;
  padding: 0;
  font-size: unset;
  min-height: 1.5em !important;
  min-width: 1.5em !important;
}
.q-calendar-mini .q-calendar-weekly__week {
  min-height: unset;
}
.q-calendar-mini .q-calendar-weekly__week--wrapper {
  border-bottom: unset;
}
.q-calendar-mini .q-calendar-weekly__workweek {
  border-right: unset;
  font-size: 1em;
  max-width: var(--calendar-mini-work-week-width);
  min-width: var(--calendar-mini-work-week-width);
}
.q-calendar-mini .q-calendar-weekly__day {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  border: 0 !important;
  margin-top: 1px;
  margin-bottom: 1px;
  min-height: 1.5em !important;
}
.q-calendar-mini .q-calendar-weekly__day--content {
  margin-top: 1px;
  height: auto;
}
.q-calendar-mini .q-calendar-weekly__day-label {
  position: relative;
  vertical-align: middle;
  text-align: center;
  top: unset;
  left: unset;
}
.q-calendar-mini .q-calendar-weekly__day.q-active-date {
  color: var(--calendar-active-date-color);
}
.q-calendar-mini .q-calendar-weekly__day.q-active-date .q-btn__wrapper:before {
  background: var(--calendar-active-date-background);
}
.q-calendar-mini .q-calendar-weekly__day.q-current-day {
  color: var(--calendar-mini-current-color) !important;
  background: var(--calendar-mini-current-background) !important;
}
.q-calendar-mini .q-calendar-weekly__day.q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current);
}
.q-calendar-mini .q-calendar-weekly__day.q-selected {
  color: var(--calendar-mini-selected-color);
  background: var(--calendar-mini-selected-background) !important;
}
.q-calendar-mini .q-calendar-weekly__day.q-selected .q-calendar-weekly__day-label .q-btn__wrapper:before {
  color: var(--calendar-mini-selected-label-color) !important;
  background: var(--calendar-mini-selected-label-background) !important;
}
.q-calendar-mini .q-calendar-weekly__day.q-disabled-day {
  color: var(--calendar-disabled-date-color) !important;
  background: var(--calendar-disabled-date-background) !important;
}
.q-calendar-mini .q-calendar-weekly__day.q-outside {
  color: var(--calendar-outside-color) !important;
  background: var(--calendar-outside-background);
}
.q-calendar-mini .q-calendar-weekly__day.q-range-first, .q-calendar-mini .q-calendar-weekly__day.q-range-last {
  color: var(--calendar-mini-range-firstlast-color);
  background: var(--calendar-mini-range-firstlast-background) !important;
}
.q-calendar-mini .q-calendar-weekly__day.q-range-first .q-calendar-weekly__day-label .q-btn__wrapper:before, .q-calendar-mini .q-calendar-weekly__day.q-range-last .q-calendar-weekly__day-label .q-btn__wrapper:before {
  color: var(--calendar-mini-range-firstlast-label-color) !important;
  background: var(--calendar-mini-range-firstlast-label-background) !important;
}
.q-calendar-mini .q-calendar-weekly__day.q-range {
  color: unset;
  background: unset;
}
.q-calendar-mini .q-calendar-weekly__day.q-range.q-range-hover {
  color: var(--calendar-mini-range-hover-color);
}
.q-calendar-mini .q-calendar-weekly__day.q-range-first:before, .q-calendar-mini .q-calendar-weekly__day.q-range-last:before, .q-calendar-mini .q-calendar-weekly__day.q-range:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--calendar-mini-range-connector-color);
}
.q-calendar-mini .q-calendar-weekly__day.q-range-first.q-range-hover:before, .q-calendar-mini .q-calendar-weekly__day.q-range-last.q-range-hover:before, .q-calendar-mini .q-calendar-weekly__day.q-range.q-range-hover:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: var(--calendar-color);
  background: var(--calendar-background);
  border-top: var(--calendar-mini-range-connector-hover-border);
  border-bottom: var(--calendar-mini-range-connector-hover-border);
}
.q-calendar-mini .q-calendar-weekly__day.q-range-first .q-calendar-weekly__day-label .q-btn__wrapper:before, .q-calendar-mini .q-calendar-weekly__day.q-range-last .q-calendar-weekly__day-label .q-btn__wrapper:before {
  color: var(--calendar-mini-range-firstlast-label-color) !important;
  background: var(--calendar-mini-range-firstlast-label-background) !important;
  border-radius: 50%;
}
.q-calendar-mini .q-calendar-weekly__day.q-range-first:before {
  width: 50%;
  left: 50%;
}
.q-calendar-mini .q-calendar-weekly__day.q-range-first.q-range-hover:before {
  width: 50%;
  left: 50%;
}
.q-calendar-mini .q-calendar-weekly__day.q-range-last:before {
  width: 50%;
  right: auto;
}
.q-calendar-mini .q-calendar-weekly__day.q-range-last.q-range-first:before {
  width: 0;
}
.q-calendar-mini .q-calendar-weekly__day.q-range-last.q-range-hover:before {
  width: 50%;
  right: auto;
}
.q-calendar-mini .q-calendar-weekly__day.q-range-last.q-range-hover.q-range-first.q-range-hover:before {
  width: 0;
}
.q-calendar-mini .q-calendar-weekly__day.q-range-last.q-range-first .q-btn, .q-calendar-mini .q-calendar-weekly__day.q-range-last.q-range-last .q-btn {
  opacity: 1 !important;
}
.q-calendar-mini .q-calendar-weekly__day.q-calendar-weekly__day--droppable {
  border: var(--calendar-border-droppable) !important;
}
.q-calendar-mini .q-day-event:not(.q-day-event-void):after {
  content: "";
  width: 0.6em;
  height: 0.4em;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  background: var(--calendar-selected-background);
}
.q-calendar-mini .q-calendar-weekly__week {
  border-bottom: unset;
  max-width: 100%;
}

.q-dark div .q-calendar-mini.q-calendar-weekly,
.body--dark div .q-calendar-mini.q-calendar-weekly,
.q-calendar--dark .q-calendar-mini.q-calendar-weekly {
  color: var(--calendar-color-dark);
  background: var(--calendar-background-dark);
}
.q-dark div .q-calendar-mini .q-calendar-weekly__head-workweek,
.body--dark div .q-calendar-mini .q-calendar-weekly__head-workweek,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__head-workweek {
  border-right: unset;
  border-bottom: unset;
}
.q-dark div .q-calendar-mini .q-calendar-weekly__workweek,
.body--dark div .q-calendar-mini .q-calendar-weekly__workweek,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__workweek {
  border-right: unset;
}
.q-dark div .q-calendar-mini .q-calendar-weekly__week--wrapper,
.body--dark div .q-calendar-mini .q-calendar-weekly__week--wrapper,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__week--wrapper {
  border-bottom: unset;
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day,
.body--dark div .q-calendar-mini .q-calendar-weekly__day,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day {
  color: var(--calendar-color-dark);
  background: var(--calendar-background-dark);
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-active-date,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-active-date,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-active-date {
  color: var(--calendar-active-date-color-dark);
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-active-date .q-btn__wrapper:before,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-active-date .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-active-date .q-btn__wrapper:before {
  background: var(--calendar-active-date-background-dark);
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-current-day .q-btn__wrapper:before,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-current-day .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current-dark) !important;
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-selected,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-selected,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-selected {
  color: var(--calendar-mini-selected-color-dark);
  background: var(--calendar-mini-selected-background-dark);
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-selected .q-current-day .q-btn__wrapper:before,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-selected .q-current-day .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-selected .q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current);
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-disabled-day,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-disabled-day,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-disabled-day {
  color: var(--calendar-disabled-date-color-dark) !important;
  background: var(--calendar-disabled-date-background-dark) !important;
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-outside,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-outside,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-outside {
  color: var(--calendar-outside-color-dark) !important;
  background: var(--calendar-outside-background-dark) !important;
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-range,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-range,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-range {
  color: var(--calendar-mini-range-color-dark);
  background: var(--calendar-mini-range-background-dark) !important;
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-range.q-range-hover,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-range.q-range-hover,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-range.q-range-hover {
  color: var(--calendar-mini-range-hover-color-dark);
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-range-first:before, .q-dark div .q-calendar-mini .q-calendar-weekly__day.q-range-last:before, .q-dark div .q-calendar-mini .q-calendar-weekly__day.q-range:before,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-range-first:before,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-range-last:before,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-range:before,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-range-first:before,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-range-last:before,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-range:before {
  background: var(--calendar-mini-range-connector-color-dark);
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-range-first.q-range-hover:before, .q-dark div .q-calendar-mini .q-calendar-weekly__day.q-range-last.q-range-hover:before, .q-dark div .q-calendar-mini .q-calendar-weekly__day.q-range.q-range-hover:before,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-range-first.q-range-hover:before,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-range-last.q-range-hover:before,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-range.q-range-hover:before,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-range-first.q-range-hover:before,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-range-last.q-range-hover:before,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-range.q-range-hover:before {
  color: var(--calendar-color-dark);
  background: var(--calendar-background-dark);
  border-top: var(--calendar-mini-range-connector-hover-border-dark);
  border-bottom: var(--calendar-mini-range-connector-hover-border-dark);
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-range-first .q-btn, .q-dark div .q-calendar-mini .q-calendar-weekly__day.q-range-last .q-btn,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-range-first .q-btn,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-range-last .q-btn,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-range-first .q-btn,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-range-last .q-btn {
  opacity: 1 !important;
}
.q-dark div .q-calendar-mini .q-calendar-weekly__day.q-calendar-weekly__day--droppable,
.body--dark div .q-calendar-mini .q-calendar-weekly__day.q-calendar-weekly__day--droppable,
.q-calendar--dark .q-calendar-mini .q-calendar-weekly__day.q-calendar-weekly__day--droppable {
  border: var(--calendar-border-droppable-dark) !important;
}

.q-calendar-mini .q-day-event {
  margin-top: 0em;
}

.q-calendar-scheduler {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.q-calendar-scheduler__head {
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 10px;
}
.q-calendar-scheduler__resource-head {
  flex: none;
  display: flex;
  overflow: hidden;
  vertical-align: bottom;
}
.q-calendar-scheduler__resource-head--text {
  display: block;
  position: relative;
  font-size: 10px;
  text-align: center;
  vertical-align: bottom;
}
.q-calendar-scheduler__head-days-body {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 10000 1 0%;
  user-select: none;
}
.q-calendar-scheduler__head-day {
  flex: 1 1 auto;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 0;
}
.q-calendar-scheduler__head-weekday {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  user-select: none;
  padding: 4px;
  font-size: 1rem;
  justify-content: left;
}
.q-calendar-scheduler__head-day-label {
  user-select: none;
  cursor: pointer;
  left: 2px;
  bottom: 2px;
}
.q-calendar-scheduler__column-header__before {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  user-select: none;
  padding: 4px;
  font-size: 1rem;
  justify-content: left;
}
.q-calendar-scheduler__column-header__after {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  user-select: none;
  padding: 4px;
  font-size: 1rem;
  justify-content: left;
}
.q-calendar-scheduler__body {
  flex: 1 1 60%;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
}
.q-calendar-scheduler__scroll-area {
  overflow: auto;
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
}
.q-calendar-scheduler__pane {
  width: 100%;
  overflow-y: hidden;
  flex: none;
  display: flex;
  align-items: flex-start;
}
.q-calendar-scheduler__day-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.q-calendar-scheduler__resource-row {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  flex: 10000 1 0%;
}
.q-calendar-scheduler__resources-body {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  flex: 10000 1 0%;
  user-select: none;
}
.q-calendar-scheduler__resource {
  display: flex;
  flex: 1;
  user-select: none;
  padding: 4px;
  font-size: 1rem;
  justify-content: left;
  overflow: hidden;
  align-items: center;
  flex-wrap: wrap;
}
.q-calendar-scheduler__resource-text {
  display: flex;
  position: relative;
  font-size: 12px;
  align-items: center;
  flex-wrap: wrap;
}
.q-calendar-scheduler__day {
  flex: 1;
  width: 0;
  position: relative;
}
.q-calendar-scheduler__day-resource {
  height: 100%;
}

.q-calendar-scheduler__head.q-calendar__sticky {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 2;
}

.q-calendar-scheduler__resource-head.q-calendar__sticky {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 3;
}

.q-calendar-scheduler__resource.q-calendar__sticky {
  position: sticky;
  left: 0;
  z-index: 1;
}

.q-calendar-scheduler__head {
  color: var(--calendar-color);
  background: var(--calendar-background);
  border-bottom: var(--calendar-border);
}
.q-calendar-scheduler__resource-head {
  border-right: var(--calendar-border);
  color: var(--calendar-color);
  background: var(--calendar-background);
}
.q-calendar-scheduler__column-header--before {
  border-bottom: var(--calendar-border);
}
.q-calendar-scheduler__column-header--after {
  border-top: var(--calendar-border);
}
.q-calendar-scheduler__head-day {
  font-weight: var(--calendar-head-font-weight);
  border-right: var(--calendar-border);
}
.q-calendar-scheduler__head-day:last-child {
  border-right: none;
}
.q-calendar-scheduler__head-day.q-disabled-day {
  color: var(--calendar-disabled-date-color);
  background: var(--calendar-disabled-date-background) !important;
}
.q-calendar-scheduler__head-day.q-active-date .q-btn {
  color: var(--calendar-active-date-color);
}
.q-calendar-scheduler__head-day.q-active-date .q-btn__wrapper:before {
  background: var(--calendar-active-date-background);
}
.q-calendar-scheduler__head-day.q-current-day {
  color: var(--calendar-current-date-color);
}
.q-calendar-scheduler__head-day.q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current);
}
.q-calendar-scheduler__resource-row {
  border-bottom: var(--calendar-border);
}
.q-calendar-scheduler__resource-row:first-child {
  border-top: none !important;
}
.q-calendar-scheduler__resource-row:last-child {
  border-bottom: none;
}
.q-calendar-scheduler__resource {
  border-right: var(--calendar-border);
  color: var(--calendar-color);
  background: var(--calendar-background);
}
.q-calendar-scheduler__day {
  border-right: var(--calendar-border);
}
.q-calendar-scheduler__day:last-child {
  border-right: none !important;
}
.q-calendar-scheduler__day.q-disabled-day {
  color: var(--calendar-disabled-date-color);
  background: var(--calendar-disabled-date-background) !important;
}
.q-calendar-scheduler__day-resource--droppable {
  border: var(--calendar-border-droppable);
}

.q-dark div .q-calendar-scheduler__head,
.body--dark div .q-calendar-scheduler__head,
.q-calendar--dark .q-calendar-scheduler__head {
  color: var(--calendar-color-dark);
  background: var(--calendar-background-dark);
  border-bottom: var(--calendar-border-dark);
}
.q-dark div .q-calendar-scheduler__resource-head,
.body--dark div .q-calendar-scheduler__resource-head,
.q-calendar--dark .q-calendar-scheduler__resource-head {
  border-right: var(--calendar-border-dark);
  color: var(--calendar-color-dark);
  background: var(--calendar-background-dark);
}
.q-dark div .q-calendar-scheduler__column-header--before,
.body--dark div .q-calendar-scheduler__column-header--before,
.q-calendar--dark .q-calendar-scheduler__column-header--before {
  border-bottom: var(--calendar-border-dark);
}
.q-dark div .q-calendar-scheduler__column-header--after,
.body--dark div .q-calendar-scheduler__column-header--after,
.q-calendar--dark .q-calendar-scheduler__column-header--after {
  border-top: var(--calendar-border-dark);
}
.q-dark div .q-calendar-scheduler__head-day,
.body--dark div .q-calendar-scheduler__head-day,
.q-calendar--dark .q-calendar-scheduler__head-day {
  border-right: var(--calendar-border-dark);
}
.q-dark div .q-calendar-scheduler__head-day.q-disabled-day,
.body--dark div .q-calendar-scheduler__head-day.q-disabled-day,
.q-calendar--dark .q-calendar-scheduler__head-day.q-disabled-day {
  color: var(--calendar-disabled-date-color-dark);
  background: var(--calendar-disabled-date-background-dark) !important;
}
.q-dark div .q-calendar-scheduler__head-day.q-active-date .q-btn,
.body--dark div .q-calendar-scheduler__head-day.q-active-date .q-btn,
.q-calendar--dark .q-calendar-scheduler__head-day.q-active-date .q-btn {
  color: var(--calendar-active-date-color-dark);
}
.q-dark div .q-calendar-scheduler__head-day.q-active-date .q-btn__wrapper:before,
.body--dark div .q-calendar-scheduler__head-day.q-active-date .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-scheduler__head-day.q-active-date .q-btn__wrapper:before {
  background: var(--calendar-active-date-background-dark);
}
.q-dark div .q-calendar-scheduler__head-day.q-current-day,
.body--dark div .q-calendar-scheduler__head-day.q-current-day,
.q-calendar--dark .q-calendar-scheduler__head-day.q-current-day {
  color: var(--calendar-current-date-color-dark);
}
.q-dark div .q-calendar-scheduler__head-day.q-current-day .q-btn__wrapper:before,
.body--dark div .q-calendar-scheduler__head-day.q-current-day .q-btn__wrapper:before,
.q-calendar--dark .q-calendar-scheduler__head-day.q-current-day .q-btn__wrapper:before {
  border: var(--calendar-border-current-dark);
}
.q-dark div .q-calendar-scheduler__resource-row,
.body--dark div .q-calendar-scheduler__resource-row,
.q-calendar--dark .q-calendar-scheduler__resource-row {
  border-bottom: var(--calendar-border-dark);
}
.q-dark div .q-calendar-scheduler__resource,
.body--dark div .q-calendar-scheduler__resource,
.q-calendar--dark .q-calendar-scheduler__resource {
  border-right: var(--calendar-border-dark);
  color: var(--calendar-color-dark);
  background: var(--calendar-background-dark);
}
.q-dark div .q-calendar-scheduler__day,
.body--dark div .q-calendar-scheduler__day,
.q-calendar--dark .q-calendar-scheduler__day {
  border-right: var(--calendar-border-dark);
}
.q-dark div .q-calendar-scheduler__day.q-disabled-day,
.body--dark div .q-calendar-scheduler__day.q-disabled-day,
.q-calendar--dark .q-calendar-scheduler__day.q-disabled-day {
  color: var(--calendar-disabled-date-color-dark);
  background: var(--calendar-disabled-date-background-dark) !important;
}
.q-dark div .q-calendar-scheduler__resource,
.body--dark div .q-calendar-scheduler__resource,
.q-calendar--dark .q-calendar-scheduler__resource {
  border-right: var(--calendar-border-dark);
}
.q-dark div .q-calendar-scheduler__day-resource--droppable,
.body--dark div .q-calendar-scheduler__day-resource--droppable,
.q-calendar--dark .q-calendar-scheduler__day-resource--droppable {
  border: var(--ccalendar-border-droppable-dark);
}

.q-calendar-resource {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.q-calendar-resource__body {
  flex: 1 1 60%;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
}
.q-calendar-resource__scroll-area {
  overflow: auto;
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
}
.q-calendar-resource__day-container {
  position: relative;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  flex-direction: column;
}
.q-calendar-resource__head {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
  font-size: 10px;
}
.q-calendar-resource__resource-head {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
  font-size: 10px;
  padding: 2px;
}
.q-calendar-resource__head-intervals {
  display: flex;
  flex-direction: row;
}
.q-calendar-resource__head-label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 10px;
  padding: 2px;
  user-select: none;
}
.q-calendar-resource__resources-body {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
}
.q-calendar-resource__resource-row {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1;
}
.q-calendar-resource__resource {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  user-select: none;
  padding: 4px;
  font-size: 1rem;
  overflow: hidden;
}
.q-calendar-resource__resource-text {
  display: flex;
  position: relative;
  font-size: 12px;
  align-items: center;
  flex-wrap: wrap;
}
.q-calendar-resource__resource-intervals {
  display: flex;
  position: relative;
}

.q-calendar-resource__head.q-calendar__sticky {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 2;
}

.q-calendar-resource__resource-head.q-calendar__sticky {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 3;
}

.q-calendar-resource__resource.q-calendar__sticky {
  position: sticky;
  left: 0;
  z-index: 1;
}

.q-calendar-resource__head {
  color: var(--calendar-color);
  background: var(--calendar-background);
}
.q-calendar-resource__resource-head {
  border-right: var(--calendar-border);
  border-bottom: var(--calendar-border);
  color: var(--calendar-color);
  background: var(--calendar-background);
}
.q-calendar-resource__resource-head:last-child {
  border-right: none;
}
.q-calendar-resource__head-label {
  border-right: var(--calendar-border);
  border-bottom: var(--calendar-border);
}
.q-calendar-resource__head-label:last-child {
  border-right: none;
}
.q-calendar-resource__resource {
  border-bottom: var(--calendar-border);
  border-right: var(--calendar-border);
  color: var(--calendar-color);
  background: var(--calendar-background);
}
.q-calendar-resource__resource--droppable {
  border: var(--calendar-border-droppable);
}
.q-calendar-resource__resource-interval {
  border-bottom: var(--calendar-border);
  border-right: var(--calendar-border);
}
.q-calendar-resource__resource-interval:last-child {
  border-right: none;
}
.q-calendar-resource__resource-interval--droppable {
  border: var(--calendar-border-droppable);
}

.q-calendar-resource__resource-row:last-child > .q-calendar-resource__resource {
  border-bottom: none;
}

.q-calendar-resource__resource-row:last-child > .q-calendar-resource__resource-intervals > .q-calendar-resource__resource-interval {
  border-bottom: none;
}

.q-dark div .q-calendar-resource__head,
.body--dark div .q-calendar-resource__head,
.q-calendar--dark .q-calendar-resource__head {
  color: var(--calendar-color-dark);
  background: var(--calendar-background-dark);
}
.q-dark div .q-calendar-resource__body,
.body--dark div .q-calendar-resource__body,
.q-calendar--dark .q-calendar-resource__body {
  border-top: var(--calendar-border-dark);
}
.q-dark div .q-calendar-resource__resource-head,
.body--dark div .q-calendar-resource__resource-head,
.q-calendar--dark .q-calendar-resource__resource-head {
  border-right: var(--calendar-border-dark);
  border-bottom: var(--calendar-border-dark);
  color: var(--calendar-color-dark);
  background: var(--calendar-background-dark);
}
.q-dark div .q-calendar-resource__head-label,
.body--dark div .q-calendar-resource__head-label,
.q-calendar--dark .q-calendar-resource__head-label {
  border-right: var(--calendar-border-dark);
  border-bottom: var(--calendar-border-dark);
}
.q-dark div .q-calendar-resource__head-label--droppable,
.body--dark div .q-calendar-resource__head-label--droppable,
.q-calendar--dark .q-calendar-resource__head-label--droppable {
  border: var(--calendar-border-droppable-dark);
}
.q-dark div .q-calendar-resource__resource,
.body--dark div .q-calendar-resource__resource,
.q-calendar--dark .q-calendar-resource__resource {
  border-bottom: var(--calendar-border-dark);
  border-right: var(--calendar-border-dark);
  color: var(--calendar-color-dark);
  background: var(--calendar-background-dark);
}
.q-dark div .q-calendar-resource__resource--droppable,
.body--dark div .q-calendar-resource__resource--droppable,
.q-calendar--dark .q-calendar-resource__resource--droppable {
  border: var(--calendar-border-droppable-dark);
}
.q-dark div .q-calendar-resource__resource-interval,
.body--dark div .q-calendar-resource__resource-interval,
.q-calendar--dark .q-calendar-resource__resource-interval {
  border-bottom: var(--calendar-border-dark);
  border-right: var(--calendar-border-dark);
}